import { defineComponent } from 'vue';
import FooterMobile from '@/components/layout/footer/footer-mobile/FooterMobile.vue';
import HomeHeaderMobile from '@/components/layout/header/header-mobile/HeaderMobile.vue';
import HeaderDesktop from '@/components/layout/header/header-desktop/HeaderDesktop.vue';
import HomeAside from '@/components/home/home-aside-desktop/HomeAsideDesktop.vue';
import HomeSection from '@/components/home/home-section/HomeSection.vue';
export default defineComponent({
    components: {
        FooterMobile,
        HomeHeaderMobile,
        HeaderDesktop,
        HomeAside,
        HomeSection
    }
});
